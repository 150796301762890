import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import TextInput from "../../Forms/InputVarients/TextInput";
import CloseButton from "../../SVG/CloseButtonSVG";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SurpriseInfoOutline from "../../SVG/SurpriseInfoSVG";
import SurpriseInfo from "../../SVG/SurpriseInfo.svg";

const InfoBoxModal = ({ isOpen, onClose, title, isOops=false }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };

    const handleCancel = () => {
        setModalOpen(false);
        console.log("Called the on close function")
        onClose();
    };

    return (
        <Modal
            centered
            open={modalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            {isOops ? (
                null
            ) : (
                <div className="flex justify-center items-center relative">
                    <img src={SurpriseInfo} alt="Surprise Info" style={{ width: '80px', height: '80px' }} />
                </div>
            )}
            <div className="flex justify-center items-center py-4 pt-6 font-semibold text-md tracking-wider text-center">
                {title}
            </div>
            <div className=" px-2 flex justify-center items-center gap-3 mt-3 ">
                <PrimaryButton text={"Done"} size="Medium" onClick={handleCancel}/>
            </div>
        </Modal>
    );
};

export default InfoBoxModal;

import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import AutoSizeTextArea from "../../Forms/InputVarients/AutoSizeTextArea";
import CloseButton from '../../SVG/CloseButtonSVG';
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { updateDocumentDescriptionApi } from "../../../features/dataRoomSlice";
import { Input, message, Modal, notification } from 'antd';

const { TextArea } = Input;

const AddDescriptionModal = ({ isOpen, onClose, documentList }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const maxLength = 200; // Set your character limit here

  useEffect(() => {
    setModalOpen(isOpen);
    console.log(documentList?.description === "")
    setInputValue(documentList?.description === "" ? "" : documentList?.description)
  }, [isOpen]);

  const handleOk = () => {
    setModalOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      setInputValue(value);
    }
  };

  const handleClick = async () => {
    setLoading(true);
    const body = {
      documentId: documentList._id,
      description: inputValue,
    };
    if (inputValue === documentList.description) {
      // notification.error({
      //     message: "Error",
      //     description: "Same description"
      // })
      message.error("Same description")
      setLoading(false)
      return
    }
    console.log(body);
    await dispatch(updateDocumentDescriptionApi(body)).then((response) => {
      if (response) {
        message.success("Description Added")
      } else {
        // notification.error({
        //     message: "Error",
        //     description: "Failed to update document description"
        // })
        message.error("Failed to update document description")
      }
      setLoading(false);
      handleOk();
    });
  };

  return (
    <Modal
      title={<span style={{ fontSize: '20px' }}>File Description</span>}
      centered
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
        <CloseButton />
      </button>

      <div className="flex flex-col">
        <h1 className="text-[#111827] text-[16px]">
          Provide a description for this file
        </h1>
        <TextArea
          placeholder="Add the file description"
          value={inputValue}
          onChange={handleInputChange}
          rows={5}
        />
        <div className="text-right text-gray-500 mt-2">
          {inputValue.length}/{maxLength} characters
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <SecondaryButton text={"Cancel"} onClick={handleCancel} size="medium" isDisabled={loading} />
        <PrimaryButton text={inputValue === "" ? "Add" : "Update"} onClick={handleClick} size="medium" isDisabled={loading} isLoading={loading} />
      </div>
    </Modal>
  );
};

export default AddDescriptionModal;


import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { updateDocumentDescriptionApi } from "../../../features/dataRoomSlice";
import { Modal, Input, notification, message } from "antd";
import CloseButton from "../../SVG/CloseButtonSVG";
import AutoSizeTextArea from "../../Forms/InputVarients/AutoSizeTextArea";
import InputTextModal from "../../Modals/InputTextModal";

const { TextArea } = Input; // Import TextArea from Ant Design

const AddFolderDescriptionModal = ({ isOpen, onClose, documentList }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [modalOpen, setModalOpen] = useState(isOpen);
  const maxLength = 300; // Set your character limit here
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setModalOpen(isOpen);
    setInputValue("")
  }, [isOpen]);

  const handleOk = () => {
    setModalOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setModalOpen(false);
    onClose();
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      setInputValue(value);
    }
  };

  const handleClick = async () => {
    setLoading(true)
    const body = {
      documentId: documentList,
      description: inputValue,
    };

    await dispatch(updateDocumentDescriptionApi(body)).then((response) => {
      if (response?.meta.requestStatus === "fulfilled") {
        // notification.info({
        //     message: "Success",
        //     description: "Description Added"
        // })
        message.success("Description Added")
      } else {
        // notification.error({
        //     message: "Error",
        //     description: "Description Additoin Failed"
        // })
        message.error("Description Additoin Failed")
      }
      handleOk();
      setLoading(false)
    });
  };

  return (
    <Modal
      title={<span style={{ fontSize: '20px' }}>File Description</span>}
      centered
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
        <CloseButton />
      </button>

      <div className="flex flex-col">
        <h1 className="text-[#111827] text-[16px]">
          Provide a description for this file
        </h1>
        <TextArea
          placeholder="Add the folder description"
          value={inputValue}
          onChange={handleInputChange}
          rows={5}
        />
        <div className="text-right text-gray-500 mt-2">
          {inputValue.length}/{maxLength} characters
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <SecondaryButton text={"Cancel"} onClick={handleCancel} size="medium" isDisabled={loading} />
        <PrimaryButton text={"Add"} onClick={handleClick} size="medium" isDisabled={loading} isLoading={loading} />
      </div>
    </Modal>
  );
};

export default AddFolderDescriptionModal;


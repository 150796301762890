import React, { useState, useEffect } from 'react';
import { message, Modal, notification } from 'antd';
import TextInput from "../../Forms/InputVarients/TextInput";
import CloseButton from '../../SVG/CloseButtonSVG';
import SecondaryButton from '../../Buttons/SecondaryButton';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { generateSignedUrlForDownload } from '../../../features/dataRoomSlice';

const DownloadModal = ({ isOpen, onClose, documentList }) => {
  console.log(documentList)
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [loading, setLoading] = useState(false)
  console.log(documentList)
  const { directoryName } = useParams();
  console.log(directoryName)
  const dispatch = useDispatch();

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const handleOk = () => {
    setModalOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setModalOpen(false);
    onClose();
  };

  const onDownloadClick = async () => {
    setLoading(true)
    const splitPath = documentList?.path.split('/')
    const body = {
      id: documentList?._id,
      directory: directoryName ? directoryName : splitPath[splitPath.length - 2],
      filename: documentList?.name
    }
    console.log("Document Name", (documentList?.path.split('/')));

    const result = await dispatch(generateSignedUrlForDownload(body));
    console.log(result);
    if (result && result.payload && result.payload.url) {
      const downloadUrl = result.payload.url;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.download = documentList?.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false)
      // notification.info({
      //     message: "Download Succeeded",
      //     description: "File has been downloaded"
      // })
      message.success("File has been downloaded")
    }
    handleCancel();
  }

  console.log(documentList)

  return (
    <Modal
      title={<span style={{ fontSize: '20px' }}>Download File</span>}
      centered
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
        <CloseButton />
      </button>
      <div className=" px-2 flex flex-col gap-3 ">
        <div className=" flex flex-col gap-1 ">
          <div>
            {"Are you sure you want to download this file ?"}
          </div>
          <div>
            {"Once downloaded it will be saved to your local device."}
          </div>
        </div>
        <div className=" w-full flex flex-row justify-between mt-3 ">
          <div>
            <SecondaryButton text={"Cancel"} size="medium" isDisabled={loading} onClick={() => { setModalOpen(false) }} />
          </div>
          <div>
            <PrimaryButton text={"Download"} size="medium" onClick={onDownloadClick} isDisabled={loading} isLoading={loading} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;

import React, { useState, useEffect } from "react";
import { Modal, Input, notification, message } from "antd";
import CloseButton from "../../SVG/CloseButtonSVG";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { copyFileApi } from "../../../features/dataRoomSlice";

const MakeCopyModal = ({ isOpen, onClose, documentList }) => {
  const [modalOpen, setModalOpen] = useState(isOpen);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const handleOk = () => {
    setModalOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleLock = async () => {
    setLoading(true)
    const body = {
      documentId: documentList._id,
    }

    await dispatch(copyFileApi(body)).then((response) => {
      if (response?.meta.requestStatus === "fulfilled") {
        message.success("File Copied Successfully")
      } else {
        message.error("File Copying Failed")
      }
      setLoading(false)
      handleOk();
    })
  }

  return (
    <Modal
      title={<span style={{ fontSize: "20px" }}>{"Make a Copy"}</span>}
      centered
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <button
        className="absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all"
        onClick={handleCancel}
      >
        <CloseButton />
      </button>
      <div className=" px-2 flex flex-col gap-3 ">
        <div>
          Creating a copy will allow you to work on a duplicate version without affecting the original.
        </div>
        <div className=" w-full flex flex-row justify-between mt-3 ">
          <div>
            <SecondaryButton text={"Cancel"} size="medium" isDisabled={loading} onClick={handleCancel} />
          </div>
          <div>
            <PrimaryButton text={"Make a Copy"} size="medium" onClick={handleLock} isLoading={loading} isDisabled={loading} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MakeCopyModal;



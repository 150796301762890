import { useState, useEffect } from "react";
import { createFileApi, generateSignedUrl } from "../../../features/dataRoomSlice";
import { useDispatch } from "react-redux";
import { Modal, Input, notification, Spin, message } from 'antd';
import CloseButton from '../../SVG/CloseButtonSVG';
import SecondaryButton from '../../Buttons/SecondaryButton';
import PrimaryButton from '../../Buttons/PrimaryButton';

const AddFileModal = ({ isOpen, onClose, documentList, onSecondaryClose }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const handleOk = () => {
    setModalOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setModalOpen(false);
    onSecondaryClose();
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  async function sendFileName(fileName) {
    const body = {
      directory: documentList,
      filename: fileName
    };
    const response = await dispatch(generateSignedUrl(body));
    return response;
  }

  async function uploadFile(uploadUrl, file) {
    const readFileAsBinary = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    };

    try {
      const fileBinary = await readFileAsBinary(file);
      const formData = new FormData();
      formData.append('file', file);
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        body: fileBinary
      });

      return response.ok;
    } catch (error) {
      console.log("Error Uploading File, ", error);
      return false;
    }
  }

  const handleClick = async () => {
    if (!file) {
      console.log("No file selected");
      return;
    }

    setLoading(true);

    try {
      const response = await sendFileName(file.name);
      const uploadUrl = response?.payload?.url;

      if (uploadUrl) {
        const uploadFileResult = await uploadFile(uploadUrl, file);
        if (uploadFileResult) {
          const finalBody = {
            directory: documentList,
            file: file.name
          };
          const final = await dispatch(createFileApi(finalBody));
          if (final.error) {
            message.error("File already exists.")
          } else {
            message.success("File succesfully added to the current directory.")
          }
        } else {
          message.error("Failed to add file to preferred directory. Please try again.")
        }
      } else {
        message.error("Failed to get the upload URL. Please try again.")
      }
    } catch (error) {
      console.error("Error in handleClick", error);
      message.error("An unexpected error occurred. Please try again.")
    } finally {
      setLoading(false);
      handleOk();
    }
  };

  return (
    <Modal
      title={<span style={{ fontSize: '20px' }}>Add File</span>}
      centered
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
        <CloseButton />
      </button>
      <div className="flex flex-col">
        <h1 className="text-[#111827] text-[16px]">File Name</h1>

        <Input
          borderColor="#9E9E9E"
          onChange={handleFileChange}
          type={"file"}
        />
      </div>

      <div className="flex justify-between mt-3">
        <SecondaryButton text={"Cancel"} onClick={handleCancel} size="medium" isDisabled={loading} />
        <PrimaryButton
          text={"Add File"}
          onClick={handleClick}
          size="medium"
          disabled={loading}
          isLoading={loading}
          style={{
            width: '120px', // Fixed width for the button
            backgroundColor: '#87CEEB', // Light blue background color
            border: 'none', // Remove border to match background color
            color: '#ffffff', // Text color
            display: 'flex', // Use flex to align spinner and text
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      </div>
    </Modal>
  );
};

export default AddFileModal;


import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { useState, useEffect } from "react";
import { updateTagsApi } from "../../../features/dataRoomSlice";
import { useDispatch } from "react-redux";
import { message, Modal } from 'antd';
import TextInput from "../../Forms/InputVarients/TextInput";
import CloseButton from '../../SVG/CloseButtonSVG';
import ModalSection from './ModalSection';

const AddTagsModal = ({ isOpen, onClose, documentList, onSecondaryClose, tagsPreviousString }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (documentList.tags) {
      const initialTag = documentList.tags.split(',')[0].trim();
      setTag(initialTag);
    }
  }, [documentList]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleSubmit = async () => {
    if (!inputValue.trim()) {
      message.error("Please enter a tag");
      return;
    }
    setLoading(true);
    const newTags = `${documentList.tags}, ${inputValue.trim()}`;
    const body = {
      documentId: documentList.id,
      tags: newTags,
    };
    await dispatch(updateTagsApi(body)).then((response) => {
      setLoading(false);
      if (response) {
        message.success("Tag has been added Successfully");
      }
      onClose(); // Close modal after successful tag update
    });
  };

  return (
    <Modal
      title={<span style={{ fontSize: '20px' }}>Add Tag</span>}
      centered
      open={isOpen} // Use isOpen prop directly
      onCancel={onSecondaryClose}
      footer={null} // Custom footer with buttons
    >
      <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={onSecondaryClose}>
        <CloseButton />
      </button>

      <div className="flex flex-row w-full items-center gap-2">
        <div className="flex items-center border rounded p-2 flex-grow">
          <input
            className="flex-grow p-1 outline-none"
            placeholder="Tag Name"
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
        <PrimaryButton text={"Add"} onClick={handleSubmit} size="medium" isDisabled={loading} className="ml-2" />
      </div>
    </Modal>
  );
};

export default AddTagsModal;

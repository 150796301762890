import PrimaryButton from "../../Buttons/PrimaryButton";
import PrimaryDangerButton from "../../Buttons/PrimaryDangerButton";
import closeSvg from "../../../assets/common/close.svg";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { deleteDocumentsApi } from "../../../features/dataRoomSlice";
import { message, Modal, notification } from 'antd';
import TextInput from "../../Forms/InputVarients/TextInput";
import CloseButton from '../../SVG/CloseButtonSVG';

const DeleteFileModal = ({ isOpen, onClose, documentList }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const handleOk = () => {
    setModalOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setModalOpen(false);
    onClose();
  };

  const handleClick = async () => {
    setLoading(true)
    const documentIdList = Array.isArray(documentList) ? documentList : [documentList];
    const body = {
      documentIdList: documentIdList.map((value) => value._id),
    }
    await dispatch(deleteDocumentsApi(body)).then((response) => {
      setLoading(false);
      if (response) {
        message.success("File has been deleted Succesfully")
        handleCancel();
      }
    })
  };
  return (
    <Modal
      title={<span style={{ fontSize: '20px' }}>Delete File</span>}
      centered
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
        <CloseButton />
      </button>
      <p className=" text-[#111827] text-[14px] pr-6">
        Deleting this file will permanently remove it. Are you sure you want
        to proceed with the deletion?
      </p>

      <div className=" flex justify-between">
        <SecondaryButton text={"Cancel"} onClick={handleCancel} size="medium" isDisabled={loading} />
        <PrimaryDangerButton text={"Delete"} onClick={handleClick} size="medium" isDisabled={loading} isLoading={loading} />
      </div>
    </Modal>
  );
};

export default DeleteFileModal;

import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { useState, useEffect } from "react";
import { createDirectoryApi } from "../../../features/dataRoomSlice";
import { useDispatch } from "react-redux";
import { message, Modal, notification } from 'antd';
import TextInput from "../../Forms/InputVarients/TextInput";
import CloseButton from '../../SVG/CloseButtonSVG';
import ModalSection from './ModalSection';

const AddFolderModal = ({ isOpen, onClose, onSecondaryClose }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const handleOk = () => {
    setModalOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setModalOpen(false);
    onSecondaryClose();
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleClick = async () => {
    setLoading(true);
    const body = {
      directory: inputValue,
    };
    const response = await dispatch(createDirectoryApi(body))
      .then((reponse) => {
        if (reponse.payload.error) {
          setModalOpen(false)
          setLoading(false)
          return;
        }
        setModalVisible(true);
        setModalOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Failed to create directory", error);
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    handleClick();
  };

  return (
    <>
      {modalVisible ? (
        <ModalSection title="Folder Info" closeHandler={() => { setModalOpen(false); handleOk(); }} />
      ) : null}
      <Modal
        title={<span style={{ fontSize: '20px' }}>Add Folder</span>}
        centered
        open={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
          <CloseButton />
        </button>

        <div className="flex flex-col">
          <h1 className="text-[#111827] text-[16px]">Folder Name</h1>

          <TextInput
            placeholder="Company name"
            borderColor="#9E9E9E"
            value={inputValue}
            maxCharLength={75}
            onChangeEvent={handleInputChange}
          />
        </div>

        <div className="flex justify-between mt-3">
          <SecondaryButton text={"Cancel"} onClick={handleCancel} size="Medium" isDisabled={loading} />
          <PrimaryButton
            text={loading ? "Adding..." : "Add Folder"}
            onClick={handleSubmit}
            size="medium"
            isDisabled={loading}
            isLoading={loading}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddFolderModal;

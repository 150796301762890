import { Upload, Button, Avatar, Space, message, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { useState, useEffect } from "react";
import TextInput from "../../Forms/InputVarients/TextInput";
import CloseButton from '../../SVG/CloseButtonSVG';
import ModalSection from './ModalSection';
import { useDispatch } from "react-redux";
import { generateSignedUrl, getTokenInfoApi, QuestionAsk } from "../../../features/dataRoomSlice";

const AskQuestionModal = ({ isOpen, onClose, documentList }) => {
  const [inputValue, setInputValue] = useState("");
  const [description, setDescription] = useState("");
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [file, setFile] = useState(null); // Store the selected file here
  const dispatch = useDispatch()

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const getUser = async () => {
      const result = await dispatch(getTokenInfoApi());
      setUserDetails(result?.payload?.user)
    }
    getUser();
  }, [dispatch])

  const handleOk = () => {
    setModalOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setModalOpen(false);
    onClose();
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setDescription(value);
  };

  const handleFileChange = (info) => {
    console.log(info);
    setFile(info.file);
  };

  const handleSubmit = async () => {
    setLoading(true);

    // Handle the file upload when "Ask Question" is clicked
    let fileUrl = null;
    console.log(file);
    if (file) {
      try {
        console.log("This is the document list : ", documentList)
        const sendFileName = async (fileName) => {
          const body = {
            directory: documentList?.folderName,
            filename: fileName,
            uploadType: "attachement"
          };
          const response = await dispatch(generateSignedUrl(body));
          return response;
        };

        const uploadFile = async (uploadUrl, file) => {
          const fileBinary = await file.arrayBuffer(); // Convert file to binary
          const response = await fetch(uploadUrl, {
            method: 'PUT',
            body: fileBinary
          });
          return response.ok;
        };

        const response = await sendFileName(file.name);
        const uploadUrl = response?.payload?.url;

        if (uploadUrl) {
          const uploadFileResult = await uploadFile(uploadUrl, file);
          if (uploadFileResult) {
            fileUrl = file.name;
            message.success("File successfully added to the current directory.");
          } else {
            message.error("Failed to upload file. Please try again.");
            setLoading(false);
            return;
          }
        } else {
          message.error("Failed to get the upload URL. Please try again.");
          setLoading(false);
          return;
        }
      } catch (error) {
        message.error("Failed to upload file.");
        console.log(error)
        setLoading(false);
        return;
      }
    }

    // Continue with the rest of the form submission
    const body = {
      documentId: documentList._id,
      subject: inputValue,
      description: description,
      fileName: fileUrl
    };
    const response = await dispatch(QuestionAsk(body));

    if (response?.meta.requestStatus === "fulfilled") {
      message.success("This question has been sent to the admin");
    } else {
      message.error("Failed to send the data to the admin. Please try to ask the question again.");
    }

    setLoading(false);
    handleOk();
  };

  return (
    <>
      {modalVisible ? (
        <ModalSection title="Question Info" closeHandler={() => { setModalOpen(false); handleCancel(); }} />
      ) : null}
      <Modal
        title={
          <Space align="center">
            <Avatar>{(userDetails?.username || "ON")[0]}</Avatar>
            <div>
              <div>{userDetails?.username || "Owner"}</div>
              <div style={{ color: 'gray' }}>Today at 10:30 AM</div>
            </div>
          </Space>
        }
        centered
        open={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
          <CloseButton />
        </button>

        <div className="flex flex-col">
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <div>
              <label style={{ display: 'block', marginBottom: 4 }}>Subject</label>
              <TextInput
                placeholder="Write Here..."
                value={inputValue}
                onChangeEvent={handleInputChange}
              />
            </div>

            <div>
              <label style={{ display: 'block', marginBottom: 4 }}>Description</label>
              <TextInput
                placeholder="Write your response..."
                value={description}
                onChangeEvent={handleDescriptionChange}
                type="textarea"
              />
            </div>

            <Upload
              style={{ width: '100%' }}
              beforeUpload={() => false} // Prevent auto upload
              onChange={handleFileChange}
            >
              <Button icon={<PlusOutlined />} className='w-[fit-content] m-[5px] mt-[15px]'>
                Attach a file
              </Button>
            </Upload>

            <div className="flex justify-between mt-3">
              <SecondaryButton text={"Cancel"} onClick={handleCancel} size="medium" isDisabled={loading} />
              <PrimaryButton
                text={loading ? "Submitting..." : "Ask Question"}
                onClick={handleSubmit}
                size="medium"
                isDisabled={loading}
                isLoading={loading}
              />
            </div>
          </Space>
        </div>
      </Modal>
    </>
  );
};

export default AskQuestionModal;
